import React from "react";
import { Button } from "react-bootstrap";
import Proptypes from "prop-types";
import styles from "./PrimaryButton.module.css";

interface PrimaryButtonProps {
  title: any;
  btnClass?: string;
  onClick?: any;
}

const PrimaryButton = (props: PrimaryButtonProps) => {
  const onClickButton = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Button
      onClick={onClickButton}
      className={`${styles.primaryButton} background-blue font-family-poppins ${props.btnClass}`}
    >
      {props.title}
    </Button>
  );
};

PrimaryButton.propTypes = {
  title: Proptypes.any,
  btnClass: Proptypes.string,
};

export { PrimaryButton };
