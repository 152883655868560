import React from "react";
import { Row, Navbar, Nav, Container, Image } from "react-bootstrap";
import { LOGO } from "../../assets/image";
import { PrimaryButton } from "../PrimaryButton";
import styles from "./Header.module.css";

const Header = () => {
  const onClickHeaderLink = (section_id: string) => {
    let element: any = document.getElementById(section_id);
    if (element) {
      element.scrollIntoView({ behaviour: "smooth" });
    }
  };
  return (
    <Row className={`${styles.headerRow}`} id="site-header">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand
            href="/"
            className="color-blue font-24 font-family-poppins"
          >
            <Image className={styles.logoImg} src={LOGO} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`me-auto ms-auto ${styles.navigation_items}`} defaultActiveKey="/home">
              <Nav.Link
                as={"button"}
                onClick={() => {
                  onClickHeaderLink("home-banner-section");
                }}
                className="bg-transparent border-0  p-lg-0"
              >
                <span
                  className={"w-100 pe-5 ps-5 " + styles.navLinkSpan}
                >
                  Home
                </span>
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  onClickHeaderLink("products-section");
                }}
                as={"button"}
                className="bg-transparent border-0  p-lg-0"
              >
                <span
                  className={"w-100 pe-5 ps-5 " + styles.navLinkSpan}
                >
                  Products
                </span>
              </Nav.Link>
              <Nav.Link
                as={"button"}
                onClick={() => {
                  onClickHeaderLink("why-votemax-section");
                }}
                className="bg-transparent border-0 p-lg-0"
              >
                <span
                  className={"w-100 pe-5 ps-5 " + styles.navLinkSpan}
                >
                  Why Votemax
                </span>
              </Nav.Link>
              <Nav.Link
                as={"button"}
                onClick={() => {
                  onClickHeaderLink("faq-section");
                }}
                className="bg-transparent border-0  p-lg-0"
              >
                <span className={"w-100 pe-5 ps-5 " + styles.navLinkSpan}>
                  FAQ
                </span>
              </Nav.Link>
            </Nav>
            <Nav className={`${styles.navigation_items}`}>
              <Nav.Item> 
                <PrimaryButton
                  onClick={() => {
                    onClickHeaderLink("contact-us-section");
                  }}
                  title="Contact Us"
                />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Row>
  );
};

export { Header };
