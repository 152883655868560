import React, { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import styles from "./BannerAdmin.module.css";
import { getContentApi, updateBannerContentApi } from "../../apis";

const BannerAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    titleError: "",
    descriptionError: "",
    subTitleError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);

  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      titleError: "",
      subTitleError: "",
    };
    if (title?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, titleError: "Field cannot be empty !" };
    }
    if (subTitle?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, subTitleError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if(validate()){
      setIsLoadingSubmission(true);
      const data = {
        id: content?.banner?._id,
        title,
        subTitle,
      };
      updateBannerContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.banner) {
      setSubTitle(content?.banner?.subTitle);
      setTitle(content?.banner?.title);
    }
  }, [content]);

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrors({ ...errors, titleError: "" });
            }}
            placeholder="Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.titleError}</p>
        </Form.Group>
        <Form.Group>
          <Form.Label>Sub Title</Form.Label>
          <Form.Control
            type="text"
            value={subTitle}
            onChange={(event) => {
              setSubTitle(event.target.value);
              setErrors({ ...errors, subTitleError: "" });
            }}
            placeholder="Sub Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.subTitleError}</p>
        </Form.Group>

        <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
          {isLoadingSubmission ? (
            <Spinner size={"sm"} animation="border" variant="primary" />
          ) : (
            "Update"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export { BannerAdmin };
