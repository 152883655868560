import { toast } from "react-toastify";
import { axiosInstance } from "../axios";

const authApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/login-user", body);
    successCallback(response);
  } catch (error: any) {
    errorCallback(error);
    console.log(error);
    toast.error(error?.response?.data?.msg)
  }
};

const changePasswordApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/change-password", body);
    successCallback(response);
  } catch (error: any) {
    errorCallback(error);
    toast.error(error?.response?.data?.message || "something went wrong! ", {
      autoClose: 3000,
    });
  }
};

const resetPasswordApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/reset-password", body);
    successCallback(response);
  } catch (error: any) {
    errorCallback(error);
  }
};

const verifyotpAndUpdatePassword = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/verify-otp", body);
    successCallback(response);
  } catch (error: any) {
    errorCallback(error);
  }
};

export { authApi, verifyotpAndUpdatePassword, changePasswordApi, resetPasswordApi };
