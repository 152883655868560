import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  authApi,
  resetPasswordApi,
  verifyotpAndUpdatePassword,
} from "../../apis/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./LoginAdmin.module.css";

const LoginAdmin = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [resetEmail, setResetEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [resetPassword, setResetPassword] = useState<string>("");
  const [confirmResetPassword, setConfirmResetPassword] = useState<string>("");
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({
    otpError: "",
    resetPasswordError: "",
    confirmPasswordError: "",
  });
  const navigate = useNavigate();
  const onLogin = () => {
    authApi(
      {
        email,
        password,
      },
      (data) => {
        localStorage.setItem("isLoggedIn", JSON.stringify(true));
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", JSON.stringify(data.data.user));
        navigate("/admin/banner");
      },
      (error) => {
        
      }
    );
  };

  const modalCloseHandler = () => {
    setOtpSent(false);
    setOpenResetModal(false);
    setConfirmResetPassword("");
    setResetPassword("");
    setResetEmail("");
    setOtp("");
  };

  const validate = () => {
    let isValid = true;
    let _error = {
      otpError: "",
      resetPasswordError: "",
      confirmPasswordError: "",
    };
    if (otp?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, otpError: "Field cannot be empty !" };
    }
    if (resetPassword?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, resetPasswordError: "Field cannot be empty !" };
    } else if (resetPassword?.trim()?.length < 5) {
      isValid = false;
      _error = {
        ..._error,
        resetPasswordError: "New password must contain atleast 5 characters",
      };
    }
    if (confirmResetPassword?.trim()?.length === 0) {
      isValid = false;
      _error = {
        ..._error,
        confirmPasswordError: "Field cannot be empty !",
      };
    } else if (confirmResetPassword?.trim()?.length < 5) {
      isValid = false;
      _error = {
        ..._error,
        confirmPasswordError:
          "Confirm password must contain atleast 5 characters",
      };
    } else if (confirmResetPassword?.trim() !== resetPassword?.trim()) {
      isValid = false;
      _error = {
        ..._error,
        confirmPasswordError:
          "Confirm password does not match with new password!",
      };
    }
    setErrors(_error);
    return isValid;
  };

  const getOtp = () => {
    if (
      !resetEmail ||
      !resetEmail?.includes("@") ||
      !resetEmail?.includes(".")
    ) {
      toast.error("Please enter a valid Email");
      return;
    }
    setLoader(true);
    resetPasswordApi(
      {
        email: resetEmail,
      },
      (data) => {
        setLoader(false);
        console.log(data);
        setOtpSent(true);
        toast.success(data?.data);
      },
      (error) => {
        setLoader(false);
        toast.error(error?.response?.data);
      }
    );
  };

  const updatePassword = () => {
    if (validate()) {
      setLoader(true);
      verifyotpAndUpdatePassword(
        {
          email: resetEmail,
          otp: otp,
          password: resetPassword,
        },
        (data) => {
          setLoader(false);
          toast.success(data?.data);
          modalCloseHandler();
        },
        (error) => {
          setLoader(false);
          toast.error(error?.response?.data);
        }
      );
    }
  };

  const renderResetPasswordModal = () => {
    return (
      <Modal show={openResetModal} centered>
        <Modal.Header closeButton onHide={() => modalCloseHandler()}>
          Reset Password
        </Modal.Header>
        <Modal.Body>
          {otpSent ? (
            <Form className="w-lg-75">
              <Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={resetEmail}
                    disabled
                    onChange={(event) => {
                      setResetEmail(event.target.value);
                    }}
                    placeholder=""
                  />
                </Form.Group>
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={(event) => {
                    setOtp(event.target.value);
                    setErrors({ ...errors, otpError: "" });
                  }}
                  placeholder="Enter OTP"
                />
                <p className={styles.errorMsg}>{errors.otpError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={resetPassword}
                  onChange={(event) => {
                    setResetPassword(event.target.value);
                    setErrors({ ...errors, resetPasswordError: "" });
                  }}
                  placeholder="Enter New Password"
                />
                <p className={styles.errorMsg}>{errors.resetPasswordError}</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmResetPassword}
                  onChange={(event) => {
                    setConfirmResetPassword(event.target.value);
                    setErrors({ ...errors, confirmPasswordError: "" });
                  }}
                  placeholder="Enter Confirm Password"
                />
                <p className={styles.errorMsg}>{errors.confirmPasswordError}</p>
              </Form.Group>
            </Form>
          ) : (
            <Form className="w-lg-75">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  value={resetEmail}
                  onChange={(event) => {
                    setResetEmail(event.target.value);
                  }}
                  placeholder="Enter your Email to get OTP"
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {otpSent ? (
            <Button
              onClick={updatePassword}
              className="mt-3"
              variant="primary"
              disabled={loader}
            >
              {loader ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Reset Password"
              )}
            </Button>
          ) : (
            <Button
              onClick={getOtp}
              className="mt-3"
              variant="primary"
              disabled={loader}
            >
              {loader ? <Spinner animation="border" size="sm" /> : "Get OTP"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <Row className="d-flex m-0 vh-100 vw-100 align-self-center justify-content-center align-items-center">
      <Col xs={11} md={8} lg={4}>
        <Card className="m-0 w-100">
          <Card.Header>Login</Card.Header>
          <Card.Body>
            <Form className="w-lg-75">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder="Email"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  placeholder="Password"
                />
              </Form.Group>
            </Form>
            <Button
              variant="link"
              onClick={() => setOpenResetModal(true)}
              className="float-end"
            >
              Forgot Password
            </Button>
            <br />
            <Button onClick={onLogin} className="mt-3" variant="success">
              LOGIN
            </Button>
          </Card.Body>
        </Card>
      </Col>
      {renderResetPasswordModal()}
    </Row>
  );
};

export { LoginAdmin };
