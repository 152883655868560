import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { changePasswordApi } from "../../apis/auth";
import { toast } from "react-toastify";
import styles from "./ChangePasswordAdmin.module.css";

const ChangePasswordAdmin = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [errors, setErrors] = useState<any>({
    oldPasswordError: "",
    newPasswordError: "",
    confirmNewPasswordError: "",
  });

  const validate = () => {
    let isValid = true;
    let _error = {
      oldPasswordError: "",
      newPasswordError: "",
      confirmNewPasswordError: "",
    };
    if (oldPassword?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, oldPasswordError: "Field cannot be empty !" };
    }
    if (newPassword?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, newPasswordError: "Field cannot be empty !" };
    } else if (newPassword?.trim()?.length < 5) {
      isValid = false;
      _error = {
        ..._error,
        newPasswordError: "New password must contain atleast 5 characters",
      };
    }
    if (confirmNewPassword?.trim()?.length === 0) {
      isValid = false;
      _error = {
        ..._error,
        confirmNewPasswordError: "Field cannot be empty !",
      };
    } else if (confirmNewPassword?.trim()?.length < 5) {
      isValid = false;
      _error = {
        ..._error,
        confirmNewPasswordError:
          "Confirm password must contain atleast 5 characters",
      };
    } else if (confirmNewPassword?.trim() !== newPassword?.trim()) {
      isValid = false;
      _error = {
        ..._error,
        confirmNewPasswordError:
          "Confirm password does not match with new password!",
      };
    }
    setErrors(_error);
    return isValid;
  };

  const onChangePassword = () => {
    if (validate()) {
      let user: any = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
      }
      changePasswordApi(
        {
          id: user?._id,
          password: oldPassword,
          newPassword,
        },
        (data: any) => {
          toast.success("Password changed successfully !", { autoClose: 3000 });
          setNewPassword("");
          setOldPassword("");
          setConfirmNewPassword("");
        },
        (error: any) => {}
      );
    }
  };
  return (
    <Container className="mt-5">
      <Row className="justify-content-center" >
        <Col md={6} xs={12}>
          <Form className="w-lg-75">
            <Form.Group>
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                value={oldPassword}
                onChange={(event) => {
                  setOldPassword(event.target.value);
                  setErrors({ ...errors, oldPasswordError: "" });
                }}
                placeholder="Old Password"
              />
              <p className={styles.errorMsg}>{errors.oldPasswordError}</p>
            </Form.Group>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                  setErrors({ ...errors, newPasswordError: "" });
                }}
                placeholder="New Password"
              />
              <p className={styles.errorMsg}>{errors.newPasswordError}</p>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirn New Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmNewPassword}
                onChange={(event) => {
                  setConfirmNewPassword(event.target.value);
                  setErrors({ ...errors, confirmNewPasswordError: "" });
                }}
                placeholder="Confirn New Password"
              />
              <p className={styles.errorMsg}>
                {errors.confirmNewPasswordError}
              </p>
            </Form.Group>
          </Form>
          <Button onClick={onChangePassword} className="mt-3" variant="success">
            Change
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export { ChangePasswordAdmin };
