import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { CAM_ICON, FB_ICON, TWITTER_ICON } from "../../assets/image";
import styles from "./Footer.module.css";

const Footer = () => {
  const onClickFooterLink = (section_id: string) => {
    let element: any = document.getElementById(section_id);
    if (element) {
      element.scrollIntoView({ behaviour: "smooth" });
    }
  };

  return (
    <Row className={styles.footerRow}>
      <div className={styles.footerDiv}>
        <Container>
          <Row className="justify-content-center">
            <Col xs="4" sm="3" md="3">
              <p
                onClick={() => {
                  onClickFooterLink("home-banner-section");
                }}
                role="button"
                className="text-center text-white border-end cursor-pointer"
              >
                Home
              </p>
            </Col>
            <Col xs="4" sm="3" md="3">
              <p
                onClick={() => {
                  onClickFooterLink("products-section");
                }}
                className="text-center text-white border-end"
                role="button"
              >
                Products
              </p>
            </Col>
            <Col xs="4" sm="3" md="3">
              <p
                onClick={() => {
                  onClickFooterLink("why-votemax-section");
                }}
                className="text-center text-white border-end"
                role="button"
              >
                Why Votemax
              </p>
            </Col>
            <Col xs="4" sm="3" md="3">
              <p
                onClick={() => {
                  onClickFooterLink("faq-section");
                }}
                className="text-center text-white"
                role="button"
              >
                FAQ
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs="1">
              <Image
                src={FB_ICON}
                onClick={() =>
                  window.open("https://www.facebook.com/groups/votemaxtech")
                }
              />
            </Col>
            <Col xs="1">
              <Image
                src={TWITTER_ICON}
                onClick={() => window.open("https://twitter.com/votemaxtech")}
              />
            </Col>
            <Col xs="1">
              <Image
                src={CAM_ICON}
                onClick={() =>
                  window.open("https://www.reddit.com/r/votemaxtech/")
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Row>
  );
};

export { Footer };
