import { Navigate, Outlet } from "react-router-dom";

const LoggedInPrivateRoutes = () => {
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  if (isLoggedIn) {
    isLoggedIn = JSON.parse(isLoggedIn);
  }
  return !isLoggedIn ? <Outlet/> : <Navigate to="/admin/banner" />
};

export { LoggedInPrivateRoutes };
